import React, {forwardRef, useState} from 'react';
import {CanvasPath, ReactSketchCanvas} from 'react-sketch-canvas';
import {cn} from '~/utils';

type CanvasProps = {
  onChange?: (paths: CanvasPath[]) => void;
  backgroundImage?: string;
  canvasStyles?: React.CSSProperties;
} & React.ComponentPropsWithoutRef<'div'>;

const colors = [
  '#F00',
  '#FF9A52',
  '#FF0',
  '#008000',
  '#00F',
  '#800080',
  '#FFC0CB',
  '#A52A2A',
  '#000',
];

export const Canvas = forwardRef(
  (
    {onChange, backgroundImage, canvasStyles = {}, className}: CanvasProps,
    ref
  ) => {
    const [color, setColor] = useState('#FF9A52');

    return (
      <div
        className={cn(
          'grid cursor-[url(/images/CursorPencil.png),auto] grid-cols-[1fr_auto] flex-row-reverse justify-center gap-2',
          className
        )}
      >
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
          className="mx-auto w-full grow bg-contain bg-top bg-no-repeat"
        >
          <ReactSketchCanvas
            style={canvasStyles}
            className="mx-auto"
            canvasColor="transparent"
            strokeColor={color}
            onChange={onChange}
            ref={ref}
          />
        </div>

        <div className="sticky top-16 flex shrink-0 flex-col flex-wrap items-center gap-1 self-start rounded bg-foreground p-1">
          {colors.map(clr => (
            <button
              key={clr}
              type="button"
              className={cn(
                'size-8 shadow-xl cursor-[inherit] border border-white rounded-full',
                clr === color && 'border-white border-4 ring-2 ring-background'
              )}
              style={{backgroundColor: clr}}
              onClick={() => setColor(clr)}
            />
          ))}
        </div>
      </div>
    );
  }
);
Canvas.displayName = 'Canvas';
